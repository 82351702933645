import React from "react"

import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const query = graphql`
  query MyQuery {
    allSanityProduct {
      edges {
        node {
          id
          name
          description
          slug {
            current
          }
        }
      }
    }
  }
`

const SanityProductList = () => (
  <StaticQuery
    query={query}
    render={data => (
      <ul>
        {data.allSanityProduct.edges.map(product => (
          <li key={product.node.id}>
            <Link to={`/products/${product.node.slug.current}`}>
              {product.node.name}
            </Link>
          </li>
        ))}
      </ul>
    )}
  />
)

export default SanityProductList
