import React from "react"
import SanityProductList from "../../components/SanityProductList"
import Layout from "../../components/layouts/Layout"
import SEO from "../../components/Seo"

const Products = () => {
  return (
    <Layout>
      <SEO
        title="Minimal-intervention wines and ciders, handcrafted in Washington state"
        description="Minimal-intervention wines and ciders, handcrafted in Washington state"
      />
      <div className="prose mx-auto">
        <h1>Products</h1>
        <SanityProductList />
      </div>
    </Layout>
  )
}

export default Products
